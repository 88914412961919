const React = require('react');
const { string, bool } = require('prop-types');
const { Image } = require('nordic/image');
const { LAZYLOAD_ON, LAZYLOAD_OFF } = require('../../../../utils/constants');

const ImageCommon = ({
  className,
  lazyLoad,
  src,
  src2x,
  alt,
  size,
  preload,
  ariaHidden,
}) => (
  <Image
    src={src}
    className={className}
    alt={alt}
    srcSet={src2x ? `${src} 1x, ${src2x} 2x` : null}
    width={size}
    height={size}
    lazyload={lazyLoad ? LAZYLOAD_ON : LAZYLOAD_OFF}
    preload={preload}
    aria-hidden={ariaHidden}
  />
);

ImageCommon.propTypes = {
  src: string.isRequired,
  alt: string,
  className: string,
  lazyLoad: bool,
  src2x: string,
  size: string,
  preload: bool,
  ariaHidden: string,
};

ImageCommon.defaultProps = {
  alt: null,
  className: null,
  lazyLoad: false,
  src2x: null,
  size: null,
  preload: false,
};

module.exports = ImageCommon;
