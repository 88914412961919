/**
* Styles
*/
require('../../shared/components/pages/legacy-home/legacy-home.desktop.scss');

/**
* Module dependencies
*/
const startApp = require('../start-app');
const Home = require('../../shared/components/pages/legacy-home/legacy-home.desktop');

startApp(Home);
