const { useEffect } = require('react');

const useViewability = (ref, event_tracking, verification_resources) => {
  const [firstDelayedPrintwUrl] = event_tracking?.delayed_print ?? [];
  const [firstViewUrl] = event_tracking?.view ?? [];

  useEffect(() => {
    const delayedPrint = firstDelayedPrintwUrl ? firstDelayedPrintwUrl.url : '';
    const startViewabilitySession = () => {
      if (window.viewability && ref.current && firstViewUrl?.url) {
        const session = window.viewability.addSession(verification_resources, delayedPrint);
        session.setMeliVaTrackUrl(firstViewUrl.url);
        session.start(ref.current);
      }
    };

    window.addEventListener('viewability-enabled', startViewabilitySession, { once: true });

    if (window.viewability && window.viewability.version !== '1.0.0') {
      window.viewability.addSession(ref.current, firstViewUrl.url, verification_resources, delayedPrint);
      window.removeEventListener('viewability-enabled', startViewabilitySession);
    }

    return () => {
      window.removeEventListener('viewability-enabled', startViewabilitySession);
    };
  }, [ref, event_tracking, verification_resources]);
};

module.exports = useViewability;
