const React = require('react');
const classnames = require('classnames');
const Props = require('./props');

const namespace = 'ui-homes-icon ui-homes-icon--gift';

const IconGift = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 4C0.5 3.72386 0.723858 3.5 1 3.5H8C8.27614 3.5 8.5 3.72386 8.5 4V10C8.5 10.2761 8.27614 10.5 8 10.5H1C0.723858 10.5 0.5 10.2761 0.5 10V4Z" stroke="white" />
    <line x1="4.5" y1="4" x2="4.5" y2="11" stroke="white" />
    <path d="M4 3.5H4.5V3V2C4.5 1.17157 3.82843 0.5 3 0.5C2.17157 0.5 1.5 1.17157 1.5 2C1.5 2.82843 2.17157 3.5 3 3.5H4Z" stroke="white" />
    <path d="M5 3.5H4.5V3V2C4.5 1.17157 5.17157 0.5 6 0.5C6.82843 0.5 7.5 1.17157 7.5 2C7.5 2.82843 6.82843 3.5 6 3.5H5Z" stroke="white" />
  </svg>
);

IconGift.propTypes = Props.propTypes;
IconGift.defaultProps = Props.defaultProps;

module.exports = React.memo(IconGift);
