const React = require('react');
const { node, string, shape } = require('prop-types');

const Action = require('../../../commons/action');
const Section = require('../../../commons/section');

function Navigation(props) {
  const { type, title, view_more_action, children } = props;
  return (
    <Section type={type}>
      <div className="row container">
        <div className="section-header">
          <h2>{title}</h2>
          <Action className="view-history" {...view_more_action} />
        </div>
        {children}
      </div>
    </Section>
  );
}

Navigation.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  type: string.isRequired,
  view_more_action: shape(Action.propTypes),
};

Navigation.defaultProps = {
  view_more_action: null,
};

module.exports = Navigation;
