const React = require('react');
const { string, bool, shape, arrayOf, number } = require('prop-types');
const Button = require('@andes/button');
const Section = require('../../../../commons/section');
const BenefitItem = require('../benefit-item');
const LoyaltyBuyLevel6DesktopHeader = require('./header');

const LoyaltyBuyLevel6Desktop = ({
  header,
  benefits,
  action,
  benefits_description,
  benefits_title,
}) => (
  <Section className="loyalty-buylevel6">
    <div className="row container">
      <div className="loyalty-buylevel6__wrapper">
        <LoyaltyBuyLevel6DesktopHeader {...header} />
        <div className="loyalty-buylevel6__body">
          <span className="loyalty-buylevel6__body-title">
            {benefits_title}
          </span>
          <div className="loyalty-buylevel6__body-benefits">
            {benefits.map((item) => (
              <BenefitItem
                {...item}
                center={benefits.length === 3}
                key={item.description}
              />
            ))}
          </div>
        </div>
        {action && (
          <div className="loyalty-buylevel6__action">
            <span className="loyalty-buylevel6__action-title">
              {benefits_description}
            </span>
            <Button href={action.target} size="large">
              {action.label?.text}
            </Button>
          </div>
        )}
      </div>
    </div>
  </Section>
);

LoyaltyBuyLevel6Desktop.propTypes = {
  header: shape({
    title: string.isRequired,
    title_size: number,
    pricing: shape({
      currency: string,
      price: shape({
        units: string.isRequired,
        cents: number,
      }),
      from: string,
      from_label: string,
      time: string,
      is_crossed_out_from: bool,
      is_only_pill: bool,
      show_divider: bool,
    }),
    gradient_color: shape({
      deg: string,
      first_color: string,
      percentage: string,
      second_color: string,
    }),
    logo: shape({
      title: string,
      src: string,
      alt: string,
    }),
  }).isRequired,
  benefits: arrayOf(
    shape({
      icon: shape({
        url: shape({
          src: string,
        }),
      }),
      description: string,
      primary_color: string,
    }),
  ).isRequired,
  action: shape({
    label: shape({
      text: string,
      color: string,
    }),
    target: string,
  }).isRequired,
  benefits_title: string.isRequired,
};

module.exports = LoyaltyBuyLevel6Desktop;
