const React = require('react');
const { string, bool, shape } = require('prop-types');

const classNames = require('classnames');

const Image = require('../image');

function SquaredItem(props) {
  const { permalink, className, imgSize, lazyLoad, title, picture } = props;
  return (
    <div className="squared-item__wrapper">
      <a
        href={permalink}
        className={classNames('squared-item', className)}
      >
        <Image size={imgSize} lazyLoad={lazyLoad} alt={title} {...picture} />
        <div className="squared-item__content">
          {title
            && (
            <p className="squared-item__title" aria-hidden="true">
              {title}
            </p>
            )}
        </div>
      </a>
    </div>
  );
}

SquaredItem.propTypes = {
  id: string.isRequired,
  lazyLoad: bool.isRequired,
  title: string.isRequired,
  className: string,
  permalink: string.isRequired,
  picture: shape(Image.propTypes).isRequired,
  imgSize: string,
};

SquaredItem.defaultProps = {
  className: null,
  imgSize: null,
};

module.exports = SquaredItem;
