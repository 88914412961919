const React = require('react');
const { string, bool, func, shape, oneOf } = require('prop-types');
const Modal = require('@andes/modal');
const Button = require('@andes/button');
const { Image } = require('nordic/image');
const Spinner = require('@andes/spinner');
const componentInterstitialService = require('../../../../services/component-interstitial');
const CloseModalEmbed = require('./close-modal-embed');

const LoyaltyInterstitial = ({
  url,
  height,
  width,
  show,
  deviceType,
  ariaLabel,
  image,
  primary_action,
  secondary_action,
  experiments,
  type,
}) => {
  const [showModal, SetshowModal] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const BUTTON_HEIGHT = 80;

  const onIframeLoad = () => setLoading(false);

  const handleTrackEvents = (buttonData) => {
    const { label, target, handler } = buttonData;
    const isInterested = handler !== 'notInterestedHandler';

    if (!isInterested) {
      SetshowModal(false);
    }
    const eventName = isInterested
      ? 'trackInterestedHandlerClickEvent'
      : 'trackNotInterestedHandlerClickEvent';

    const trackData = {
      eventName,
      label,
      target,
      experiments,
      type,
    };

    if (eventName in componentInterstitialService) {
      componentInterstitialService[eventName](trackData);
    }
  };

  const onButtonClick = (action) => {
    const { handler, target } = action;

    if (
      !(
        componentInterstitialService && handler && componentInterstitialService[handler] && target
      )
    ) {
      return;
    }

    componentInterstitialService[handler](target);
    handleTrackEvents(action);
  };

  React.useEffect(() => {
    if (!url) {
      componentInterstitialService.trackShowedInterstitial({ experiments, type });
    }
  }, []);
  // TODO bug hydrate server client.
  React.useEffect(() => {
    if (show) {
      SetshowModal(show);
    }
  }, [show]);
  const InterstitialOld = () => (
    <Modal
      data-testid="interstitial"
      open={showModal}
      srLabel={ariaLabel}
      dialog={deviceType === 'desktop'}
      type={deviceType === 'desktop' ? 'large' : 'full'}
      className="interstitial--old"
      maxWidth={width || 485}
      maxHeight={Number(height) + BUTTON_HEIGHT || 550}
    >
      <Spinner modifier="fullscreen" size="large" show={isLoading} />
      <iframe
        scrolling="no"
        src={url}
        onLoad={onIframeLoad}
        width={width || 485}
        height={Number(height) + BUTTON_HEIGHT || 550}
      />
    </Modal>
  );
  const Insterestitial = () => (
    <Modal
      data-testid="interstitial"
      open={showModal}
      srLabel={ariaLabel}
      type={deviceType === 'desktop' ? 'large' : 'full'}
      closable={false}
      className="interstitial"
    >
      <div className="interstitial-image-container">
        <Image
          {...image}
          alt={image.alt}
          src={image.src['2x'] || image.src['1x']}
          lazyload="off"
        />
      </div>
      <div className="interstitial-buttons-container">
        <div className="interstitial-buttons">
          {[primary_action, secondary_action].map((button) => (
            <Button
              key={button.id}
              hierarchy={button.hierarchy}
              data-testid={button.id}
              onClick={() => onButtonClick(button)}
              style={{
                ...('text_color' in button && {
                  color: button.text_color,
                }),
                ...('background_color' in button && {
                  color: button.background_color,
                }),
              }}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  );

  if (url) {
    return showModal ? <InterstitialOld /> : <CloseModalEmbed />;
  }
  return showModal ? <Insterestitial /> : null;
};

LoyaltyInterstitial.propTypes = {
  url: string,
  height: string,
  width: string,
  deviceType: string.isRequired,
  ariaLabel: string,
  show: bool,
  image: shape({
    alt: string,
    title: string,
    src: shape({
      url: string,
      '1x': string,
      '2x': string,
    }),
  }),
  primary_action: shape({
    id: string,
    handler: string,
    label: string,
    target: string,
    hierarchy: oneOf(['loud', 'transparent', 'quiet']),
  }),
  secondary_action: shape({
    id: string,
    handler: string,
    label: string,
    target: string,
    hierarchy: oneOf(['loud', 'transparent', 'quiet']),
  }),
  eventHandlers: shape({
    interestedHandler: func,
    notInterestedHandler: func,
    trackInterestedHandlerClickEvent: func,
    trackNotInterestedHandlerClickEvent: func,
  }),
  experiments: shape({
    'interstitial-data': string,
  }),
};

LoyaltyInterstitial.defaultProps = {
  show: true,
};

module.exports = LoyaltyInterstitial;
