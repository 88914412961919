const React = require('react');
const { node, array, string, func, shape, object, bool } = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');
const Style = require('nordic/style');
const { Image } = require('nordic/image');
const Action = require('../../../commons/action');
const Section = require('../../../commons/section');
const withTracker = require('../../../commons/with-tracker');

function Container({ children, className, components, isMobile }) {
  const Elem = isMobile ? 'a' : 'div';
  return (
    <Elem
      href={
        isMobile ? components.find((c) => c.view_more).view_more.target : null
      }
      className={className}
    >
      {children}
    </Elem>
  );
}

Container.propTypes = {
  children: node.isRequired,
  className: string.isRequired,
  components: array.isRequired,
  isMobile: bool.isRequired,
};

function PaymentData({
  children,
  i18n,
  deviceType,
  components,
  promos,
  type,
  siteId,
  paymentStyles,
  openModal,
  logosSize,
  ...rest
}) {
  if (!components.length) {
    return null;
  }
  const title = i18n.gettext('Medios de pago');

  const isMobile = deviceType === 'mobile';

  const componentsCollector = [[]];
  let lastComponent = components[0];
  components.forEach((component) => {
    component.id = `${component.type}-${component.title}`;
    if (lastComponent.type !== component.type) {
      componentsCollector.push([]);
    }
    componentsCollector[componentsCollector.length - 1].push(component);
    if (component.type === 'promo') {
      promos
        .sort((a, b) => b.max_installments - a.max_installments)
        .forEach((promo) => {
          componentsCollector[componentsCollector.length - 1].push({
            id: `promo-${promo.issuer.id}`,
            type: 'promo-logo',
            target: component.view_more.target,
            modal_title: component.view_more.modal_title,
            ...promo,
          });
        });
    }
    lastComponent = component;
  });
  return (
    <Section type={`${type} ${siteId}`}>
      <Style renderChildrenInPlace={false}>{Object.values(paymentStyles).join(' ')}</Style>
      <div className="container">
        <Container
          {...{
            children,
            i18n,
            deviceType,
            components,
            promos,
            type,
            siteId,
            paymentStyles,
            openModal,
            logosSize,
            ...rest,
          }}
          className="payment-data-container"
          isMobile={isMobile}
        >
          <h2 className="clipped">{title}</h2>
          {componentsCollector.map((componentList) => (
            <div
              key={componentList[0].id}
              className={`payment-data-group payment-data-group-${componentList[0].type}`}
            >
              {componentList.map((component) => {
                const label = component.view_more && component.view_more.label
                  ? `${component.title}, ${component.view_more.label}`
                  : component.title;
                const ariaLabelAction = component.html_target !== '_blank'
                  ? label
                  : null;
                return (
                  <div
                    key={component.id}
                    className={`payment-data-section payment-data-section-${component.type}`}
                  >
                    {component.icon_url
                    && (isMobile ? (
                      <div className="payment-data-icon">
                        <Image alt={component.title} src={component.icon_url} />
                      </div>
                    ) : (
                      <a
                        className="payment-data-icon"
                        href={component.view_more.target}
                        onClick={
                          !component.html_target ? openModal : null
                        }
                        target={component.html_target}
                        data-title={component.view_more.modal_title}
                      >
                        <Image
                          alt={
                            component.title
                              ? component.title
                              : component.view_more.modal_title
                          }
                          src={component.icon_url}
                        />
                      </a>
                    ))}
                    {component.title && (
                      <div className="payment-data-title">
                        {component.title}
                      </div>
                    )}
                    {component.subtitle && (
                      <div className="payment-data-subtitle">
                        {component.subtitle}
                      </div>
                    )}
                    {component.view_more && component.view_more.label && (
                      <div className="payment-data-subtitle">
                        {isMobile ? (
                          <div className="ui-link">
                            {component.view_more.label}
                          </div>
                        ) : (
                          <Action
                            {...component.view_more}
                            data-title={component.view_more.modal_title}
                            onClick={
                              !component.html_target ? openModal : null
                            }
                            htmlTarget={component.html_target}
                            aria-label={ariaLabelAction}
                            id={null}
                          />
                        )}
                      </div>
                    )}
                    {component.issuer && (
                      <div className="payment-data-issuer">
                        {isMobile ? (
                          <div
                            className={`payments-${logosSize} _${component.issuer.id}-${logosSize}`}
                          />
                        ) : (
                          <a
                            href={`${component.target}#_${component.issuer.id}`}
                            onClick={openModal}
                            data-title={component.modal_title}
                          >
                            <div
                              className={`payments-${logosSize} _${component.issuer.id}-${logosSize}`}
                            >
                              <span className="clipped">{component.modal_title}</span>
                            </div>
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </Container>
      </div>

      {children}
    </Section>
  );
}

PaymentData.propTypes = {
  children: node,
  components: array.isRequired,
  deviceType: string.isRequired,
  logosSize: string.isRequired,
  openModal: func,
  paymentStyles: shape({
    sprite_css: string,
    picture_css: string,
  }).isRequired,
  promos: array,
  siteId: string.isRequired,
  type: string.isRequired,
  i18n: object.isRequired,
};

PaymentData.defaultProps = {
  children: null,
  openModal: null,
  promos: [],
};

module.exports = withTracker(injectI18n(PaymentData));
