const React = require('react');
const { string, arrayOf, bool, object, shape } = require('prop-types');

/* eslint-env browser */
const CarouselFree = require('@andes/carousel-free');
const divideByGroupsOf = require('../divide-groups');
const CollectionItem = require('./collection-item');
const SquaredItem = require('../../../commons/squared-item');
const componentCtrService = require('../../../../services/component-ctr');
const win = require('../../../../window');

const { CarouselFreeSlide } = CarouselFree;

const whenIdle = win.requestIdleCallback || setTimeout;

class Collection extends React.Component {
  inputRef = React.createRef(null);

  constructor(props) {
    super(props);
    this.track = this.track.bind(this);
  }

  componentDidMount() {
    // I do need to get the dom node here, so disable lint.
    if ('IntersectionObserver' in win) {
      // eslint-disable-next-line
      this.element = this.inputRef.current; // ReactDOM.findDOMNode(this);
      if (this.element) {
        // element is null when WrappedComponent returns null
        this.observer = new win.IntersectionObserver(this.track, {
          threshold: 0.5,
        });
        this.observer.observe(this.element);
      }
    }
  }

  // Tracks the wrapped component.
  track(entries) {
    const { items, track } = this.props;

    if (!entries[0].isIntersecting) return;
    this.observer.unobserve(this.element);
    this.observer.disconnect();
    whenIdle(() => {
      componentCtrService.notifyPrint(items[0].track);
      componentCtrService.notifyPrint(track);
    });
  }

  render() {
    const { lazyLoad, imgSize, items, deviceType, ...rest } = this.props;
    const groupedItems = divideByGroupsOf(items, 2);

    if (deviceType === 'tablet') {
      return (
        <div className="collection">
          <CarouselFree>
            <CarouselFreeSlide>
              <div className="main-item">
                <CollectionItem
                  {...{ lazyLoad, imgSize, items, deviceType, ...rest }}
                />
              </div>
            </CarouselFreeSlide>
            {groupedItems.map((group) => (
              <CarouselFreeSlide key={group.id}>
                <div className="other-items-group">
                  {group.elements.map((element) => (
                    <SquaredItem
                      imgSize={imgSize}
                      key={element.id}
                      permalink={element.target_url}
                      lazyLoad={lazyLoad}
                      {...element}
                    />
                  ))}
                </div>
              </CarouselFreeSlide>
            ))}
          </CarouselFree>
        </div>
      );
    }

    return (
      <div className="collection">
        <div className="main-item">
          <CollectionItem
            {...{ lazyLoad, imgSize, items, deviceType, ...rest }}
          />
        </div>
        <div className="other-items">
          {items.map((item) => (
            <SquaredItem
              imgSize={imgSize}
              key={item.id}
              permalink={item.target_url}
              lazyLoad={lazyLoad}
              {...item}
            />
          ))}
        </div>
      </div>
    );
  }
}

Collection.propTypes = {
  discover_label: string,
  items: arrayOf(shape({})).isRequired,
  lazyLoad: bool.isRequired,
  restClient: object.isRequired,
  view_more_action: shape({
    target: string.isRequired,
  }).isRequired,
  title: string.isRequired,
  imgSize: string,
};

Collection.defaultProps = {
  discover_label: null,
  imgSize: null,
};

module.exports = Collection;
