const React = require('react');
const Script = require('nordic/script');
const { array, arrayOf, shape, string } = require('prop-types');
const Section = require('../../../commons/section');

const stringify = (value) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return JSON.stringify([]);
  }
};

function ThbDesktop({ items }) {
  if (Array.isArray(items)) {
    const [thbProps] = items;

    if (thbProps && thbProps.content && thbProps.event_tracking) {
      return (
        <Section type="adn" className="AD_ADN_area">
          <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/top-home-banner-desktop.1.1.31.js" />

          <top-home-banner-desktop
            {...thbProps.content.texts}
            {...thbProps.content.images}
            {...thbProps.content.colors}
            print_url={stringify(thbProps.event_tracking.print)}
            click_url={stringify(thbProps.event_tracking.click)}
            view_url={stringify(thbProps.event_tracking.view)}
          />
        </Section>
      );
    }
  }

  return null;
}

ThbDesktop.propTypes = {
  items: arrayOf(shape({
    content: shape({
      texts: shape({
        primary_title: string,
        secondary_title: string,
        cta: string,
        legal: string,
      }),
      images: shape({
        picture: string,
        primary_logo: string,
        secondary_logo: string,
      }),
      colors: shape({
        card_background_color: string,
        card_text_color: string,
      }),
    }),
    event_tracking: shape({
      print: array,
      click: array,
      view: array,
    }),
  })),
};

module.exports = ThbDesktop;
