const React = require('react');
const PropTypes = require('prop-types');
const { CarouselSnappedSlide } = require('@andes/carousel-snapped');

const Image = require('../../../commons/image');
const withTracker = require('../../../commons/with-tracker');
const useViewability = require('../../../commons/viewability-hook/use-viewability');
const ScriptPrint = require('./script-print');

function CarouselItem(props) {
  const {
    title,
    permalink,
    picture,
    actions,
    event_tracking,
    index,
    itemsLenght,
    isEnabledNewMainSlider,
    verification_resources,
    deviceType,
    ...other
  } = props;

  const [firstPrintUrl] = event_tracking?.print ?? [];
  const ref = React.useRef(null);


  useViewability(ref, event_tracking, verification_resources);

  const isLazyLoad = deviceType === 'mobile' ? (index > 1 && index < itemsLenght - 1) : index > 0;
  return (
    <CarouselSnappedSlide
      key={`sale-product-${title
        ? title.replace(' ', '-').toLowerCase() : String(index)}`}
      {...other}
    >
      <a ref={ref} href={actions?.content_link?.link || permalink}>
        {picture?.originalSrc ? (
          // eslint-disable-next-line nordic/no-img-tags
          <img
            src={picture?.src}
            data-original-src={picture.originalSrc}
            className={isEnabledNewMainSlider ? 'image-main-slider' : null}
            alt={title || `main_slider_web_ml_${index}`}
          />
        ) : (
          <Image
            className={isEnabledNewMainSlider ? 'image-main-slider' : null}
            src={picture?.src}
            src2x={picture?.src2x}
            originalSrc={picture?.originalSrc}
            alt={title || `main_slider_web_ml_${index}`}
            lazyLoad={isLazyLoad}
            preload={!isLazyLoad}
          />)}
      </a>
      {firstPrintUrl?.url && <ScriptPrint url={firstPrintUrl?.url} />}
    </CarouselSnappedSlide>
  );
}

CarouselItem.defaultProps = {
  title: '',
  permalink: '',
  actions: {
    content_link: {
      link: '',
    },
  },
  event_tracking: {
    print: [],
  },
};

CarouselItem.propTypes = {
  title: PropTypes.string,
  permalink: PropTypes.string,
  picture: PropTypes.shape({
    src: PropTypes.string,
    originalSrc: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    content_link: PropTypes.shape({
      link: PropTypes.string,
    }),
  }),
  event_tracking: PropTypes.shape({
    print: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      }),
    ),
    view: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      }),
    ),
  }),
  verification_resources: PropTypes.object,
  itemsLenght: PropTypes.number,
  index: PropTypes.number,
  isEnabledNewMainSlider: PropTypes.bool,
};

module.exports = withTracker(CarouselItem);
