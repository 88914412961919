const { string } = require('prop-types');
const React = require('react');

function Action({ label, target, htmlTarget, accesibilityText, ...props }) {
  return (
    <a href={target} target={htmlTarget} {...props}>
      {label}
      {accesibilityText && <span className="clipped">{accesibilityText}</span>}
    </a>
  );
}

/**
 * Prop types and defaults
 */
Action.propTypes = {
  target: string.isRequired,
  label: string.isRequired,
  htmlTarget: string,
  accesibilityText: string,
};

Action.defaultProps = {
  htmlTarget: null,
  accesibilityText: null,
};

module.exports = Action;
