const criticalHeaders = [
  'device-memory',
  'dpr',
  'viewport-width',
  'ect',
];

function isFirstRequest(req) {
  // eslint-disable-next-line no-underscore-dangle
  return !req.cookies || (req.cookies && !req.cookies._ml_ci);
}

/**
 * The experiment should be enabled only for Chrome 94+
 * @param req
 * @returns {boolean}
 */
function isAllowedParticipant(req) {
  return !!req.browser && /^chrome/i.test(req.browser.name) && parseInt(req.browser.major, 10) >= 94;
}

/**
 * Split the experiment participants 50/50 by d2id value
 * @param req
 * @returns {boolean}
 */
function isCriticalChEnabled(req) {
  // eslint-disable-next-line no-underscore-dangle
  const d2Id = (req.headers['x-d2id'] || req.cookies._d2id || '').replace('-n', '');

  if (!d2Id) {
    return false;
  }

  return ['a', 'b', 'c', 'd', 'e', 'f', '0', '1'].some((l) => d2Id.endsWith(l));
}

function criticalChMiddleware(req, res, next) {
  const allowedParticipant = isAllowedParticipant(req);
  const isEnabled = allowedParticipant && isCriticalChEnabled(req);

  res.locals.criticalCH = {
    allowedParticipant,
    enabled: isEnabled,
    firstRequest: isFirstRequest(req),
  };

  if (isEnabled) {
    const criticalHeadersValue = criticalHeaders.join(', ');
    res.locals.criticalCH.criticalHeaders = criticalHeaders.map((h) => ({ name: h, value: req.get(h) }));
    res.locals.criticalCH.criticalHeadersReported = criticalHeaders.every((h) => !!req.get(h));
    res.header('Critical-CH', criticalHeadersValue);
  }

  next();
}

function getCriticalCHScript({
  enabled,
  firstRequest,
  criticalHeaders: criticalHeadersList,
  criticalHeadersReported,
} = {}) {
  return `
if (window.newrelic) {
  window.newrelic.setCustomAttribute('criticalCHTestEnabled', '${enabled}');
  window.newrelic.setCustomAttribute('criticalCHTestFR', '${firstRequest}');
  ${criticalHeadersReported === undefined
    ? ''
    : `window.newrelic.setCustomAttribute('criticalCHTestReported', '${criticalHeadersReported}');\n`}
  ${criticalHeadersList === undefined
    ? ''
    : criticalHeadersList.map((ch) => `window.newrelic.setCustomAttribute('criticalCHTest${ch.name.replace('-', '')}', '${ch.value}');`).join('\n  ')}
}`;
}

module.exports = {
  isAllowedParticipant,
  isFirstRequest,
  isCriticalChEnabled,
  criticalHeaders,
  criticalChMiddleware,
  getCriticalCHScript,
};
