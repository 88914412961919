const React = require('react');

const { shape } = require('prop-types');
const logger = require('nordic/logger');
const Section = require('../section');

const TrackNewrelic = require('../track-newrelic');

const log = logger('app/components/commons/row-reegulation');
const FiscalData = require('../../pages/legacy-home/fiscal-data');

const RegulationRow = ({
  componentProps,
  childrenProps,
  availableComponents,
}) => {
  const { deviceType, siteId } = childrenProps;
  const { components, type } = componentProps;
  const componentsList = components;
  const namespace = 'container';
  const DESKTOP = 'desktop';
  return (
    <Section type={type}>
      <div className={deviceType === DESKTOP ? namespace : null}>
        <div className={`${namespace}-${type}`}>
          {componentsList.map((component, i) => {
            const ReactComponent = availableComponents[component.type];
            if (!ReactComponent) {
              log.error(
                `Type '${component.type}' does not match any react component.`,
              );
              return (
                // eslint-disable-next-line max-len
                <TrackNewrelic
                  key={component.type}
                  newrelicNoticeError={{
                    message: `Type '${component.type}' does not match any react component.`,
                  }}
                />
              );
            }
            return (
              <ReactComponent
                key={component.id || component.type}
                {...component}
                {...childrenProps}
                elementOrder={i}
              />
            );
          })}
          <FiscalData siteId={siteId} deviceType={deviceType} />
        </div>
      </div>
    </Section>
  );
};

RegulationRow.propTypes = {
  componentProps: shape({}),
  childrenProps: shape({}),
  availableComponents: shape({}),
};

module.exports = RegulationRow;
