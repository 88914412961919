const React = require('react');
const { array } = require('prop-types');

const Schema = ({ schemaData }) => {
  if (schemaData) {
    return (
      schemaData.map(schema => (
        // eslint-disable-next-line nordic/no-script-tags
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      ))
    );
  }
  return null;
};

Schema.propTypes = {
  schemaData: array,
};

Schema.defaultProps = {
  schemaData: null,
};

module.exports = Schema;
