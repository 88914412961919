/* global newrelic: false */
const window = require('../../../window');

function TrackNewrelic({ newrelicTransactionName, newrelicNoticeError }) {
  if (window.newrelic && newrelicTransactionName) {
    newrelic.setPageViewName(newrelicTransactionName);
  } else if (window.newrelic && newrelicNoticeError) {
    newrelic.noticeError(new Error(), newrelicNoticeError);
  }

  return null;
}

module.exports = TrackNewrelic;
