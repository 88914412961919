const React = require('react');
const classnames = require('classnames');
const Props = require('./props');

const namespace = 'ui-homes-icon ui-homes-icon--check';

const IconCheck = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.78786 5.39572L8.36451 0.819074L9.39303 1.84759L3.78786 7.45276L0.606934 4.27184L1.63545 3.24332L3.78786 5.39572Z" fill="white" />
  </svg>
);

IconCheck.propTypes = Props.propTypes;
IconCheck.defaultProps = Props.defaultProps;

module.exports = React.memo(IconCheck);
