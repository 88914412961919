const React = require('react');
const { oneOf, bool, shape, string } = require('prop-types');

const Action = require('../../../commons/action');
const Image = require('../../../commons/image');
const withTracker = require('../../../commons/with-tracker');

const Advertising = ({ title, picture, lazyLoad, view_more_action, deviceType, text1, text2 }) => (
  <a className="advertising" href={view_more_action.target}>
    <div className="advertising__info">
      <span className="advertising__info--title">{title}</span>
      <span className="advertising__info--text advertising__info--text--bold">{text1}</span>
      <span className="advertising__info--text advertising__info--text--bold">{text2}</span>
      <button
        className={(deviceType === 'desktop' || deviceType === 'tablet')
          ? 'advertising__info--button andes-button andes-button--small andes-button--loud' : 'advertising__info--link'}
        aria-label={`${text1} ${text2}, ${view_more_action.label}`}
      >
        {view_more_action.label}
      </button>
    </div>
    <div className="advertising__image advertising__image--home">
      <Image {...picture} alt={title} lazyLoad={lazyLoad} />
    </div>
  </a>
);

Advertising.propTypes = {
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  lazyLoad: bool.isRequired,
  picture: shape(Image.propTypes).isRequired,
  text1: string.isRequired,
  text2: string.isRequired,
  title: string.isRequired,
  view_more_action: shape(Action.propTypes).isRequired,
};

module.exports = withTracker(Advertising);
