const React = require('react');
const { useEffect, useRef } = require('react');
const { number, string, func, oneOfType, oneOf } = require('prop-types');
const classnames = require('classnames');
const object = require('../../../../utils/global');

const namespace = 'ui-pdp-iframe';

const Iframe = ({ id, title, height, onLoad, onMessage, scrolling, src, className, ...rest }) => {
  const iframeEl = useRef(null);

  useEffect(() => {
    function handleMessage(e) {
      if (onMessage && e.data && e.data.type) {
        onMessage(e.data.type, e.data, iframeEl.current);
      }
    }
    object.addEventListener('message', handleMessage);
    return () => {
      object.removeEventListener('message', handleMessage);
    };
  });

  return (
    <iframe
      id={id}
      className={classnames(namespace, className)}
      onLoad={onLoad}
      title={title}
      src={src}
      frameBorder="0"
      scrolling={scrolling}
      style={height ? { height: `${height}px` } : null}
      ref={iframeEl}
      {...rest}
    />
  );
};

Iframe.propTypes = {
  id: string,
  className: string,
  height: oneOfType([number, string]),
  scrolling: oneOf(['auto', 'yes', 'no']),
  src: string.isRequired,
  title: string,
  onLoad: func,
  onMessage: func,
};

Iframe.defaultProps = {
  id: null,
  className: null,
  height: null,
  title: null,
  onLoad: null,
  onMessage: null,
  scrolling: 'auto',
};

module.exports = React.memo(Iframe);
