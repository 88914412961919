const React = require('react');
const { bool, string, shape, arrayOf } = require('prop-types');
const CarouselSnapped = require('@andes/carousel-snapped');
const injectI18n = require('nordic/i18n/injectI18n');

const CsvIcons = require('./nc-icons.svg');
const Action = require('../../../commons/action');
const CategoryCard = require('./category-card');
const Section = require('../../../commons/section');
const divideByGroupsOf = require('../divide-groups');
const iconsConfig = require('./icons-config');
const withTracker = require('../../../commons/with-tracker');

const { CarouselSnappedSlide } = CarouselSnapped;

const categoriesByColumn = 2;
const categoriesByRow = 7;

// Assigning these propTypes to a variable let us add an `isRequired` on each use so eslint understands what we mean.
const elementsPropType = arrayOf(
  shape({
    id: string.isRequired,
    target_url: string.isRequired,
    title: string.isRequired,
  }),
);

function CategoriesColumn({ elements }) {
  const missingBlocks = categoriesByColumn - (elements.length % categoriesByColumn);
  return (
    <div className="category-column">
      {elements.map(element => <CategoryCard key={element.id} {...element} />)}
      {missingBlocks < categoriesByColumn && <div className="filler" />}
    </div>
  );
}

CategoriesColumn.propTypes = {
  elements: elementsPropType.isRequired,
};

function CategoriesDesktop({ title, elements, siteId, lowEnd, type, view_more_action }) {
  elements.forEach((category) => {
    category.icon = iconsConfig[siteId][category.id] || category.id;
  });
  const items = divideByGroupsOf(elements, categoriesByColumn);

  return (
    <Section type={type}>
      <CsvIcons />
      <div className="row container">
        <div className="section-header">
          <h2>{title}</h2>
          {lowEnd && <Action {...view_more_action} id={null} />}
        </div>
        <div className="wrapper">
          <CarouselSnapped slidesPerView={categoriesByRow} spacing={0} paginationMode="light" paginationPosition="top">
            {items.map(item => (
              <CarouselSnappedSlide key={item.id}>
                <CategoriesColumn {...item} />
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        </div>
      </div>
    </Section>
  );
}

CategoriesDesktop.propTypes = {
  elements: elementsPropType.isRequired,
  lowEnd: bool.isRequired,
  siteId: string.isRequired,
  title: string.isRequired,
  type: string.isRequired,
  view_more_action: shape(Action.propTypes).isRequired,
};

module.exports = injectI18n(withTracker(CategoriesDesktop));
