// React & Dependencies
const React = require('react');
const { string, shape } = require('prop-types');
const classNames = require('classnames');
// RecommendationsFE Components
const {
  RecommendationsCarouselSnapped,
} = require('@recommendations-fe/carousel-snapped');
const IconFactory = require('../recommendations-icons/icon-factory');
// Homes Components
const Section = require('../section');
// Homes Grid
const RecommendationsGridSlide = require('./recommendations-grid-slide');

const namespace = 'ui-recommendations-grid';

const dynamicSlidesConfig = {
  slideAndMargin: 199,
  lastMarginRight: 12,
  maxVisibleSlides: 6,
};

const RecommendationsFeGrid = ({ className, recommendation_data, ...rest }) => (
  <Section className={`${namespace}-section`}>
    <RecommendationsCarouselSnapped
      IconFactory={IconFactory}
      Card={RecommendationsGridSlide}
      hasDynamicSlidesPerView
      dynamicSlidesConfig={dynamicSlidesConfig}
      multipleRows={2}
      apiresponse={recommendation_data}
      {...rest}
      className={classNames('row container', className)}
    />
  </Section>
);

RecommendationsFeGrid.propTypes = {
  className: string,
  recommendation_data: shape({}),
};

module.exports = RecommendationsFeGrid;
