const React = require('react');
const classNames = require('classnames');
const { string, bool, number, shape } = require('prop-types');
const { Pill } = require('@andes/badge');
const Image = require('../../../../commons/image');

const LoyaltyBuyLevel6DesktopHeader = ({
  title,
  pricing: {
    description,
    is_feature_on,
    is_only_pill,
    from,
    currency,
    time,
    price,
    is_pill_description_on,
    show_divider,
    from_label,
    pill_discount,
  },
  gradient_color,
  logo,
}) => {
  const PricesValues = () => (
    <>
      {from && (
        <div className="loyalty-buylevel6__header-preprice">
          {from_label && <span>{from_label}</span>}
          <span className="loyalty-buylevel6__header-preprice--strike">
            {from}
          </span>
          {pill_discount && (
            <Pill color="green" size="small" hierarchy="loud">
              {pill_discount.label}
            </Pill>
          )}
        </div>
      )}
      <div>
        <span
          className={classNames('loyalty-buylevel6__header-price', {
            'loyalty-buylevel6__header-price--price-only': !from,
          })}
        >
          {currency} {price?.units}
          <sup className="loyalty-buylevel6__header-cents">{price?.cents}</sup>
        </span>
        <span className="loyalty-buylevel6__header-period"> {time} </span>
      </div>
    </>
  );

  return (
    <div
      className="loyalty-buylevel6__header"
      style={{
        '--deg_loyalty': gradient_color?.deg ?? '90deg',
        '--first_color_loyalty': gradient_color?.first_color ?? '#a90f90',
        '--percentage_loyalty': gradient_color?.percentage ?? '55%',
        '--second_color_loyalty': gradient_color?.second_color ?? '#0c1a51',
      }}
    >
      <div className="loyalty-buylevel6__header__title">
        {title}
        {logo && (
          <Image
            {...logo}
            className="loyalty-buylevel6__header__title-img"
            lazyLoad
          />
        )}
      </div>
      {currency && (
        <div
          className={classNames(
            `loyalty-buylevel6__header-${
              !show_divider ? 'pricing' : 'pricing-with-separator'
            }`,
            { 'loyalty-buylevel6__header-pricing--left': is_feature_on },
          )}
        >
          {!is_only_pill && <PricesValues />}
          {is_pill_description_on ? (
            <Pill
              color="green"
              size={is_only_pill ? 'large' : 'small'}
              hierarchy="loud"
              className={classNames('loyalty-buylevel6__header-description', {
                'loyalty-buylevel6__header-description--pill':
                  is_pill_description_on,
              })}
            >
              {description}
            </Pill>
          ) : (
            <div className="loyalty-buylevel6__header-description loyalty-buylevel6__header-description--no-pill">
              <span>{description}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

LoyaltyBuyLevel6DesktopHeader.defaultProps = {
  pricing: {},
  gradient_color: {},
};

LoyaltyBuyLevel6DesktopHeader.propTypes = {
  title: string.isRequired,
  pricing: shape({
    description: string,
    from: string,
    currency: string,
    is_pill_description_on: bool,
    is_only_pill: bool,
    is_feature_on: bool,
    time: string,
    price: shape({
      units: string,
      cents: number,
    }),
  }),
  gradient_color: shape({
    deg: string,
    first_color: string,
    percentage: string,
    second_color: string,
  }),
  logo: shape({
    title: string,
    src: string,
    alt: string,
  }),
};

module.exports = LoyaltyBuyLevel6DesktopHeader;
