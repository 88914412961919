const React = require('react');
const { string, bool, shape } = require('prop-types');

const Image = require('../../../commons/image');

function EmbeddedHtml({ html }) {
  return (
    // eslint-disable-next-line
    <span dangerouslySetInnerHTML={{ __html: html }} />
  );
}

function InfoSlide({ description, target_url, link_label, title_label, picture_url, lazyLoad, description_link }) {
  return (
    <div key={title_label} className="info-slide">
      <div className="img-container">
        <Image
          className="img-container"
          alt={title_label}
          src={picture_url}
          lazyLoad={lazyLoad}
        />
      </div>
      <h2>{title_label}</h2>
      <p><EmbeddedHtml html={description} /> {description_link && (
        <a
          href={description_link?.target}
          target={description_link?.html_target || '_blank'}
        >
            {description_link?.label?.text}
        </a>)}
      </p>
      <a href={target_url}>{link_label}</a>
    </div>
  );
}

EmbeddedHtml.propTypes = {
  html: string.isRequired,
};

InfoSlide.propTypes = {
  description: string.isRequired,
  lazyLoad: bool.isRequired,
  link_label: string.isRequired,
  picture_url: string.isRequired,
  target_url: string.isRequired,
  title_label: string.isRequired,
  description_link: shape({}),
};

module.exports = InfoSlide;
