const React = require('react');
const { shape, string } = require('prop-types');

const Section = require('../../../commons/section');
const Image = require('../../../commons/image');
const Action = require('../../../commons/action');
const withTracker = require('../../../commons/with-tracker');

function Loyalty(props) {
  const { user, orders, points, free_shipping } = props;
  return (
    <Section className="loyalty">
      <div className="row container profile">
        <Image className="picture" {...user.picture} />
        <div className="block info">
          <span className="name">{user.greeting}</span>
          <span className="since">{user.content}</span>
          <Action className="link" {...user.my_account_action} />
        </div>
        <div className="block">
          <span className="title">{orders.title}</span>
          <span className="content">{orders.content}</span>
          <Action className="link" {...orders.purchases_action} />
        </div>
        <div className="block">
          <span className="title">{points.title}</span>
          <span className="content unwrap">{points.content}</span>
          <span className="points">{points.amount}</span>
          <Action className="link" {...points.know_more_action} />
        </div>
        <div className="circle">
          <Image className="picture shipping" {...free_shipping.picture} />
        </div>
        <div className="block">
          <span className="title shipping">{free_shipping.title}</span>
          <span className="content shipping">{free_shipping.content}</span>
        </div>
      </div>
    </Section>
  );
}

Loyalty.propTypes = {
  user: shape({
    picture: shape(Image.propTypes).isRequired,
    greeting: string,
    content: string,
    my_account_action: shape(Action.propTypes).isRequired,
  }).isRequired,
  orders: shape({
    title: string,
    content: string,
    purchases_action: shape(Action.propTypes).isRequired,
  }).isRequired,
  points: shape({
    title: string,
    content: string,
    amount: string,
    know_more_action: shape(Action.propTypes).isRequired,
  }).isRequired,
  free_shipping: shape({
    picture: shape(Image.propTypes).isRequired,
    title: string,
    content: string,
  }).isRequired,
};

module.exports = withTracker(Loyalty);
