/* istanbul ignore file */

const React = require('react');
const { bool, object, oneOf, string, node, array, shape, number, func } = require('prop-types');

const MeliGA = require('nordic/analytics/meli-ga');
const MelidataTrack = require('nordic/melidata/melidata-track');
const Script = require('nordic/script');
const Style = require('nordic/style');
const serialize = require('serialize-javascript');
const classNames = require('classnames');
const Head = require('nordic/head');
const injectI18n = require('nordic/i18n/injectI18n');
const RedirectApp = require('redirect-app-web');
const { AndesProvider } = require('@andes/context');
const Hotjar = require('./hotjar');
const Schema = require('../../commons/schema');
const ImageLoader = require('./image-loader');
const MeliMessages = require('./meli-messages');
const Header = require('../../commons/header');
const TrackNewrelic = require('../../commons/track-newrelic');
const ComponentList = require('../../commons/component-list');
const { getCriticalCHScript } = require('../../../../utils/critical-ch');
const Interstitial = require('./loyalty-interstitial');

function Home({
  atf,
  availableComponents,
  csrfToken,
  deviceType,
  lowEnd,
  restClient,
  siteId,
  canonical,
  translations,
  isFirstVisit,
  isLab,
  cleanPwaServiceWorkers,
  hotjar,
  mainDomain,
  children,
  newrelicTransactionName,
  trackExperiments,
  isLegacyBrowser,
  ssrComponents,
  isServerSide,
  preloadRecommendationsImgs,
  currentUser,
  melidataEventData,
  melidataPath,
  i18n,
  userConsentCookie,
  criticalCH,
  openInApp,
  imagePrefix,
  country,
  nonce,
}) {
  const forwardProps = {
    lowEnd,
    restClient,
    deviceType,
    siteId,
    canonical,
    metadata: atf.metadata,
    bookmarks: atf.bookmarks,
    isServerSide,
    currentUser,
    userConsentCookie,
    country,
  };
  const serializeProps = {
    atf,
    csrfToken,
    deviceType,
    lowEnd,
    siteId,
    canonical,
    translations,
    isFirstVisit,
    isLab,
    cleanPwaServiceWorkers,
    hotjar,
    mainDomain,
    newrelicTransactionName,
    trackExperiments,
    currentUser,
    isLegacyBrowser,
    melidataEventData,
    melidataPath,
    userConsentCookie,
    imagePrefix,
    country,
  };
  const list = ssrComponents || atf.components;
  const title = i18n.gettext('Mercado Libre');
  const browserType = isLegacyBrowser ? '.legacy' : '';
  const isPolycard = list.some((reco) => reco.is_polycard);

  return (
    <div className={classNames('home', { 'low-end': lowEnd })}>
      {openInApp && openInApp.shouldRedirect && <RedirectApp {...openInApp} />}
      <Head>
        <Schema schemaData={atf.schemadata} />
      </Head>
      <ImageLoader />
      <MeliGA siteId={siteId} platform="ML" section="HOME" page="/MAIN/" />
      <MelidataTrack path={melidataPath} type="view" experiments={trackExperiments} event_data={melidataEventData} />
      <Header
        {...atf.header}
        canonical={canonical}
        siteId={siteId}
        preloadRecommendationsImgs={preloadRecommendationsImgs}
      />
      <Style href={`legacy-home.${deviceType}.css`} critical={deviceType === 'mobile'} />
      <Style href={isPolycard ? 'polycard-web-lib.css' : `recommendations-fe-${deviceType}.css`} critical={deviceType === 'mobile'} />
      {(criticalCH && criticalCH.allowedParticipant) && <Script on="now">{getCriticalCHScript(criticalCH)}</Script>}
      <Script priority="1">{`window.ML_PRELOADED_STATE = ${serialize(serializeProps, { isJSON: true })};`}</Script>
      <Script priority="1" preload src={`vendor${browserType}.js`} />
      <Script
        priority="1"
        preload
        src={`legacy-home.${deviceType + browserType}.js`}
      />
      <h1 className="clipped">{title}</h1>
      {atf.interstitial && <Interstitial deviceType={deviceType} {...atf.interstitial} />}
      <AndesProvider locale={country?.locale?.replace('_', '-')} csrfToken={csrfToken}>
        <ComponentList
          availableComponents={availableComponents}
          list={list}
          {...forwardProps}
        />
      </AndesProvider>
      <Hotjar {...hotjar} deviceType={deviceType} />
      <MeliMessages siteId={siteId} mainDomain={mainDomain} />
      {children}

      <TrackNewrelic newrelicTransactionName={newrelicTransactionName} />
      <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
        {`
        window.viewability.setNonceForCSP('${nonce}')
        `}
      </Script>
      <noscript />
    </div>
  );
}

Home.propTypes = {
  isLab: bool.isRequired,
  cleanPwaServiceWorkers: bool.isRequired,
  isFirstVisit: bool.isRequired,
  atf: object.isRequired,
  availableComponents: object.isRequired,
  csrfToken: string.isRequired,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  lowEnd: bool,
  restClient: object.isRequired,
  siteId: string,
  canonical: string.isRequired,
  translations: object.isRequired,
  hotjar: object,
  mainDomain: string.isRequired,
  children: node,
  newrelicTransactionName: string.isRequired,
  trackExperiments: object,
  isLegacyBrowser: bool,
  ssrComponents: object,
  isServerSide: bool,
  preloadRecommendationsImgs: array,
  currentUser: shape({
    id: number.isRequired,
    nickname: string.isRequired,
  }),
  melidataEventData: object,
  melidataPath: string,
  i18n: shape({
    gettext: func,
  }).isRequired,
  userConsentCookie: bool.isRequired,
  criticalCH: object,
  openInApp: shape({
    shouldRedirect: bool,
  }),
  imagePrefix: string,
  country: shape({
    locale: string,
  }),
  nonce: string,
};

Home.defaultProps = {
  siteId: null,
  lowEnd: true,
  hotjar: null,
  children: null,
  trackExperiments: null,
  isLegacyBrowser: true,
  ssrComponents: null,
  isServerSide: null,
  preloadRecommendationsImgs: null,
  currentUser: null,
  melidataEventData: null,
  melidataPath: '/home',
  criticalCH: null,
  openInApp: null,
};

module.exports = injectI18n(Home);
