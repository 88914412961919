const React = require('react');
const Props = require('./props');

/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
const IconMeliCoins = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    alt="Melicoin"
  >
    <g clip-path="url(#a)">
      <path
        d="M14.7803 7.49999c0-4.02078-3.2595-7.280273-7.2803-7.280273C3.47921.219717.219726 3.47921.219726 7.5c0 4.0208 3.259484 7.2803 7.280274 7.2803 4.0208 0 7.2803-3.2595 7.2803-7.28031Z"
        fill="#FCEE56"
      />
      <path
        d="M2.10644 2.61002C3.39879 1.43753 5.11427.723064 6.99665.723064c4.02075 0 7.28025 3.259486 7.28025 7.280276 0 1.88239-.7144 3.59786-1.8869 4.89026 1.4681-1.332 2.39-3.25499 2.39-5.39337 0-4.0208-3.2594-7.280282-7.28023-7.280282-2.13838 0-4.0614.921972-5.39333 2.390072Z"
        fill="#FBD307"
      />
      <path
        d="M1.66065 3.15099 13.5305 11.5795c-.4416.6514-.9859 1.2273-1.6094 1.7044L.580444 5.23119c.247119-.75554.614966-1.45635 1.080206-2.0802Z"
        fill="#FBF4AF"
      />
      <path
        d="M13.419 7.49976c0-3.26894-2.6499-5.91893-5.91889-5.91893-3.26894 0-5.91893 2.65-5.91893 5.91893 0 3.26894 2.65 5.91894 5.91893 5.91894 3.26899 0 5.91889-2.65 5.91889-5.91894Z"
        fill="#E6B700"
      />
      <path
        d="M12.7974 7.50023c0-2.92569-2.3718-5.29743-5.29745-5.29743-2.9257 0-5.29744 2.37174-5.29744 5.29743 0 2.92567 2.37175 5.29747 5.29744 5.29747 2.92565 0 5.29745-2.3718 5.29745-5.29747Z"
        fill="url(#b)"
      />
      <path
        d="M11.7425 11.7428c1.1333-1.1333 1.7574-2.64002 1.7574-4.24268 0-1.40355-.4948-2.76883-1.3932-3.84429-.0622-.07451-.1731-.08444-.2476-.02219-.0745.0622-.0844.17306-.0222.24759.8457 1.01234 1.3114 2.29756 1.3114 3.61889 0 3.11458-2.5339 5.64848-5.64842 5.64848-3.11457 0-5.64844-2.5339-5.64844-5.64848 0-3.11456 2.53387-5.64844 5.64844-5.64844 1.40062 0 2.74412.51633 3.78302 1.45388.072.06501.1832.05932.2482-.01275.065-.072.0594-.18317-.0127-.24825-1.1036-.99595-2.53073-1.54444-4.01852-1.54444-1.60266 0-3.10939.62412-4.24266 1.75734-1.13323 1.13325-1.75735 2.64-1.75735 4.24266 0 1.60265.62412 3.10938 1.75735 4.24268 1.13327 1.1332 2.64 1.7573 4.24266 1.7573 1.60265 0 3.10942-.6241 4.24262-1.7573Z"
        fill="#9D7100"
      />
      <path
        d="M2.19668 2.19668C.780143 3.61324-.00000274 5.49668-.00000217 7.5c5.1e-7 1.75444.61845817 3.461 1.74143217 4.8054.07779.0931.21636.1055.3095.0277.09313-.0777.10558-.2163.02777-.3095C1.02161 10.7582.439451 9.15167.439451 7.5.43945 3.6068 3.60679.439453 7.49999.439453 11.3932.439453 14.5605 3.6068 14.5605 7.5c0 3.8932-3.1673 7.0605-7.0605 7.0605-1.75078 0-3.43014-.6454-4.72872-1.8173-.09009-.0813-.22904-.0741-.31034.0159-.08124.09-.07424.229.0159.3104C3.85635 14.3144 5.64026 15 7.5 15c2.00332 0 3.8867-.7801 5.3033-2.1967C14.2198 11.3868 15 9.50332 15 7.5s-.7802-3.88676-2.1967-5.30332C11.3867.780146 9.50331 1.8e-7 7.49999 1.9e-7 5.49667 2e-7 3.61327.780146 2.19668 2.19668Z"
        fill="#9D7100"
      />
      <path
        d="M8.75295 5.33203c-.50999 0-.95325.22402-1.25113.58386-.28359-.35984-.71732-.58386-1.25113-.58386-1.07478 0-1.76827.79119-1.76827 1.83499v2.50702h1.00567V7.14081c0-.46233.31219-.78166.7626-.78166.6625 0 .7316.55049.7316.78166v2.53323h1.00567V7.14081c0-.46233.31934-.78166.7626-.78166.6625 0 .73876.55049.73876.78166v2.53323H10.495V7.16702c.0048-1.07478-.64344-1.83499-1.74205-1.83499Z"
        fill="#9D7100"
      />
    </g>
    <defs>
      <linearGradient id="b" x1="11.2499" y1="11.2501" x2="3.48202" y2="4.01792" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FBD307" />
        <stop offset="1" stop-color="#FCEE56" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h15v15H0z" />
      </clipPath>
    </defs>
  </svg>
);

IconMeliCoins.propTypes = Props.propTypes;
IconMeliCoins.defaultProps = Props.defaultProps;

module.exports = React.memo(IconMeliCoins);
