const React = require('react');
const { string, arrayOf, shape } = require('prop-types');

const Image = require('../../image');

function LastBuySeed(props) {
  const { items, title, sub_title, target_url } = props;
  return (
    <div className="ui-card-seed__cart">
      <div className="ui-card-seed__image-container">
        <div className="quantity-1">
          <div className="col-image">
            <Image {...items[0].picture} />
          </div>
        </div>
      </div>

      <div className="ui-card-seed__content-and-hidden">
        <div className="ui-card-seed__content">
          <h2 className="ui-card-seed__title">
            <a
              href={target_url}
              className="ui-card-seed__link"
            >
              {title}
            </a>
          </h2>
          <p className="ui-card-seed__subtitle">
            {sub_title}
          </p>
        </div>
        <div className="ui-card-seed__hidden" />
      </div>
    </div>
  );
}

LastBuySeed.propTypes = {
  target_url: string.isRequired,
  items: arrayOf(
    shape({
      picture: shape(Image.propTypes),
    }),
  ).isRequired,
  sub_title: string.isRequired,
  title: string.isRequired,
};

module.exports = LastBuySeed;
