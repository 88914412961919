const React = require('react');
const { string } = require('prop-types');

const withTracker = require('../../../commons/with-tracker');

function CategoryCard(props) {
  const { id, icon, title, target_url } = props;
  return (
    <a className="category" href={target_url}>
      <div className="icon" aria-hidden="true">
        <svg className="nc-icon" aria-labelledby={id} role="img">
          <title id={id}>{title}</title>
          <use xlinkHref={`#nc-${icon}`}>{title}</use>
        </svg>
      </div>
      <p>{title}</p>
    </a>
  );
}

CategoryCard.propTypes = {
  id: string.isRequired,
  icon: string.isRequired,
  target_url: string.isRequired,
  title: string.isRequired,
};

module.exports = withTracker(CategoryCard);
