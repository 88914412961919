const slidesPerViewInRecos = (isDualCarousel, newCarousel) => {
  if (newCarousel) {
    return isDualCarousel ? 4 : 6;
  }

  return 5;
};

const slidesPerViewIncomponents = (viewportWidth) => {
  const viewport = parseInt(viewportWidth, 10);
  return viewport > 1600 ? 7 : 6;
};
const slidesInDynamicAccess = (viewportWidth) => {
  const viewport = parseInt(viewportWidth, 10);
  return viewport >= 1180 ? 6 : 5;
};

module.exports = { slidesPerViewInRecos, slidesPerViewIncomponents, slidesInDynamicAccess };
