const React = require('react');
const { object, arrayOf, shape, string } = require('prop-types');

const logger = require('nordic/logger');
const TrackNewrelic = require('../track-newrelic');

const LAZY_LOAD_AFTER = 1;
const log = logger('app/components/component-list');
const RegulationRow = require('../row-regulation');

const REGULATION_ROW = 'regulation-row';
function ComponentList({ availableComponents, list, ...childrenProps }) {
  return (
    <div>
      {list.map((component, i) => {
        if (component.type === REGULATION_ROW && component.components.length) {
          return (
            <RegulationRow
              key={component.id || component.type}
              componentProps={component}
              childrenProps={childrenProps}
              availableComponents={availableComponents}
            />
          );
        }

        const ReactComponent = availableComponents[component.type];
        if (!ReactComponent) {
          /* istanbul ignore next */
          log.error(
            `Type '${component.type}' does not match any react component.`,
          );
          return (
            // eslint-disable-next-line max-len
            <TrackNewrelic
              /* istanbul ignore next */
              key={component.id || component.type}
              newrelicNoticeError={{
                message: `Type '${component.type}' does not match any react component.`,
              }}
            />
          );
        }

        return (
          <ReactComponent
            key={component.id || component.type}
            lazyLoad={i > LAZY_LOAD_AFTER && !childrenProps.lowEnd}
            {...component}
            {...childrenProps}
            elementOrder={i}
          />
        );
      })}
    </div>
  );
}

ComponentList.propTypes = {
  availableComponents: object.isRequired,
  list: arrayOf(
    shape({
      id: string,
      type: string.isRequired,
    }),
  ).isRequired,
};

module.exports = ComponentList;
