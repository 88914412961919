const React = require('react');
const { arrayOf, bool, object, shape } = require('prop-types');

const CarouselSnapped = require('@andes/carousel-snapped');
const Snackbar = require('@andes/snackbar');
const injectI18n = require('nordic/i18n/injectI18n');
const Navigation = require('./navigation');
const NavigationSlide = require('./navigation-slide-desktop');
const withTracker = require('../../../commons/with-tracker');

const { CarouselSnappedSlide } = CarouselSnapped;

const autoHideSnackbarTime = 3000;

class NavigationDesktop extends React.Component {
  constructor(props) {
    super(props);
    const imgSize = '156';
    const slides = props.elements.map(element => ({
      ...element.item,
      lazyLoad: props.lazyLoad,
      lowEnd: props.lowEnd,
      metadata: props.metadata,
      restClient: props.restClient,
      delete: this.deleteSlide.bind(this),
      showError: this.showError.bind(this),
      closeSnackbar: this.closeSnackbar.bind(this),
      imgSize,
    }));

    this.state = {
      slides,
      snackbarData: {
        show: null,
        message: '',
        color: null,
      },
    };
  }

  checkIfSlidesHasItemsByLength(length) {
    return length > 0;
  }

  deleteSlide(id) {
    const { slides } = this.state;

    this.setState({ slides: slides.filter(slide => slide.id !== id) });
  }

  showError() {
    const { i18n } = this.props;

    this.setState({
      snackbarData: {
        message: i18n.gettext('¡Ups! Ocurrió un error. Por favor, intenta nuevamente.'),
        color: 'red',
        show: true,
      },
    }, this.closeSnackbar);
  }

  closeSnackbar() {
    setTimeout(() => this.setState({ snackbarData: { show: false } }), autoHideSnackbarTime);
  }

  render() {
    const { slides, snackbarData } = this.state;
    const slidesLength = this.checkIfSlidesHasItemsByLength(slides.length);
    if (!slidesLength) {
      return null;
    }

    return (
      <Navigation {...this.props}>
        <CarouselSnapped slidesPerView={7} pagination={false} spacing={0}>
          {slides.map(slide => (
            <CarouselSnappedSlide key={slide.id}>
              <NavigationSlide {...slide} />
            </CarouselSnappedSlide>
          ))}
        </CarouselSnapped>
        <Snackbar
          show={snackbarData.show}
          message={snackbarData.message}
          color={snackbarData.color}
        />
      </Navigation>
    );
  }
}

NavigationDesktop.propTypes = {
  elements: arrayOf(shape(NavigationDesktop.propTypes)).isRequired,
  lazyLoad: bool.isRequired,
  lowEnd: bool.isRequired,
  metadata: shape({}).isRequired,
  restClient: object.isRequired,
  i18n: object.isRequired,
};

module.exports = withTracker(injectI18n(NavigationDesktop));
