const { string } = require('prop-types');

const propTypes = {
  className: string,
};

const defaultProps = {
  className: null,
};

module.exports = { propTypes, defaultProps };

