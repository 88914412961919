const React = require('react');
const { shape, bool, string, node } = require('prop-types');

const withTracker = require('../../../commons/with-tracker');
const Image = require('../../../commons/image');
const classNames = require('classnames');

function onCardClick(target, e) {
  let parent = e.target;
  while (parent !== e.currentTarget && !parent.href) {
    parent = parent.parentElement;
  }

  // honor links over target
  if (!parent.href) {
    e.preventDefault();

    // allow users to open a new tab or window
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.which === 2) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      global.open(target);
    } else {
      global.location = target;
    }
  }
}

function OfficialStoresSlide({ title, children, target_url, logo_picture, lowEnd, background_picture, lazyLoad, isDesktop }) {
  const logoSize = '80';
  const onCardClickBound = onCardClick.bind(null, target_url);

  return (
    <div
      className={classNames('ui-card', 'official-stores-slide', !lowEnd && 'is-link')}
      role="link"
      tabIndex={isDesktop ? '-1' : '0'} // For tabindex set on Andes Carousel Snapped
      onClick={onCardClickBound}
      onKeyDown={null}
    >
      <div className="official-stores-slide-background">
        <Image {...background_picture} lazyLoad={lazyLoad} alt={title} />
      </div>
      <div className="official-stores-slide-logo">
        <Image size={logoSize} {...logo_picture} lazyLoad={lazyLoad} alt={title} />
      </div>
      <div className="title-wrapper">
        <div className="official-stores-slide-title">{title}</div>
      </div>
      {children}
    </div>
  );
}

OfficialStoresSlide.propTypes = {
  background_picture: shape(Image.propTypes),
  lazyLoad: bool.isRequired,
  logo_picture: shape(Image.propTypes).isRequired,
  lowEnd: bool.isRequired,
  target_url: string.isRequired,
  title: string.isRequired,
  children: node,
};

OfficialStoresSlide.defaultProps = {
  background_picture: {
    src: '',
    src2x: '',
  },
  children: null,
};

module.exports = withTracker(OfficialStoresSlide);
