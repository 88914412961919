const React = require('react');
const { shape, string, bool } = require('prop-types');

const classNames = require('classnames');
const Image = require('../../../commons/image');
const Ribbon = require('./ribbon');
const { makeBackgroundColor, makeGradientDesktop, getIcon } = require('./_utils');

function Slide({ title, subtitle, picture, logo: { logo }, target, ribbon, kicker, only_element, pill, top_pill }) {
  const pillStyles = {
    backgroundColor: (pill && pill.background_color) || '#00a650',
    color: (pill && pill.title && pill.title.color) || '#fff',
  };

  const topPillStyles = {
    backgroundColor: (top_pill && top_pill.background_color) || '#00a650',
    color: (top_pill && top_pill.title && top_pill.title.color) || '#fff',
  };

  const kickerStyle = {
    color: kicker && kicker.color ? kicker.color : '#FFF',
  };

  const createBackground = () => {
    let bgClass = 'partners-subscriptions__slide';
    let bgColor;
    if (only_element) {
      bgClass += '-background-color';
      bgColor = makeBackgroundColor(picture.gradient_color);
    } else {
      bgClass += '-gradient';
      bgColor = makeGradientDesktop(picture.gradient_color);
    }
    return (
      <div
        className={bgClass}
        style={bgColor}
        aria-hidden="true"
      >
        <span className="clipped">{picture.alt}</span>
      </div>
    );
  };

  return (<div className="partners-subscriptions__slide">
    <a href={target} className="partners-subscriptions__slide-link">
      {ribbon
      && <Ribbon
        rating={ribbon.rating ? ribbon.rating : null}
        text={ribbon.text ? ribbon.text.label : null}
      />}
      {createBackground()}
      <Image
        lazyLoad="off"
        {...picture}
        className={classNames('partners-subscriptions__slide-background',
          { 'partners-subscriptions__slide-background-only-element': only_element })}
        alt={title.label}
      />
      <div className={classNames('partners-subscriptions__slide-logo-text',
        { 'partners-subscriptions__slide-logo-text-only-element': only_element })}
      >
        <Image lazyLoad="off" {...logo} size="80px" className="partners-subscriptions__slide-logo" />
        <div className="partners-subscriptions__slide-texts">
          {top_pill && top_pill.title && (
            <div className="partners-subscriptions__slide-texts-pill" style={topPillStyles}>
              {top_pill.image && <div className="partners-subscriptions__slide-texts-pill--icon" aria-hidden="true">
                {getIcon(top_pill.image)}
              </div>}
              <span className="partners-subscriptions__slide-texts-pill--text">{top_pill.title.label}</span>
            </div>
          )}
          {kicker && <span className="partners-subscriptions__slide-texts-line partners-subscriptions__slide-texts-line--kicker" style={kickerStyle}>{kicker.label}</span>}
          {title && <span className="partners-subscriptions__slide-texts-line partners-subscriptions__slide-texts-line--benefit">{title.label}</span>}
          {subtitle && <span className="partners-subscriptions__slide-texts-line partners-subscriptions__slide-texts-line--partner-name">{subtitle.label}</span>}
          {pill && pill.title && (
            <div className="partners-subscriptions__slide-texts-pill" style={pillStyles}>
              {pill.image && <div className="partners-subscriptions__slide-texts-pill--icon" aria-hidden="true">
                {getIcon(pill.image)}
              </div>}
              <span className="partners-subscriptions__slide-texts-pill--text">{pill.title.label}</span>
            </div>
          )}
        </div>
      </div>
    </a>
  </div>);
}


Slide.propTypes = {
  title: shape({
    label: string.isRequired,
  }).isRequired,
  subtitle: shape({
    label: string.isRequired,
  }).isRequired,
  picture: shape({
    src: string.isRequired,
    src2x: string.isRequired,
    alt: string.isRequired,
    gradient_color: string,
  }).isRequired,
  logo: shape({
    logo: shape({
      src: string.isRequired,
      src2x: string.isRequired,
      alt: string.isRequired,
    }).isRequired,
  }).isRequired,
  kicker: shape({
    label: string.isRequired,
    color: string,
  }),
  only_element: bool,
  pill: shape({
    title: shape({
      label: string.isRequired,
      color: string,
    }),
    image: string.isRequired,
    background_color: string,
  }),
};

module.exports = Slide;
