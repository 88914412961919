const React = require('react');
const { arrayOf, shape } = require('prop-types');

const GridItem = require('./recommendations-grid-item');

const namespace = 'ui-recommendations-grid';

const RecommendationsGridSlide = ({ elements }) => (
  <div className={`${namespace}-slide`}>
    {elements.map(item => <GridItem key={item.id} namespace={namespace} {...item} />)}
  </div>
);

RecommendationsGridSlide.propTypes = {
  elements: arrayOf(shape({})).isRequired,
};

module.exports = RecommendationsGridSlide;
