const React = require('react');
const { node, string, object } = require('prop-types');

const classNames = require('classnames');

function Section({ type, className, style, children }) {
  return (
    <section data-testid={type} className={classNames(type, className)} style={style} type={type}>
      {children}
    </section>
  );
}

Section.defaultProps = {
  children: null,
  className: null,
  style: null,
  type: null,
};

Section.propTypes = {
  children: node,
  className: string,
  type: string,
  style: object,
};

module.exports = Section;
