const React = require('react');
const Home = require('./legacy-home');

const components = {};
components.adn = require('./advertising-adn');
components.categories = require('./categories/desktop');
components.collections = require('./collections');
components.discovery = require('./discovery');
components.exhibitor = require('./carousel');
components.loyalty = require('./loyalty');
components.navigation = require('./navigation/desktop');
components['official-stores'] = require('./official-stores/desktop');
components['payment-data'] = require('./payment-data/desktop');
components['site-shopping-info'] = require('./site-shopping-info/desktop');
components.regret = require('./regret');
components['complaints-book'] = require('./complaints-book');
components['help-email'] = require('./help-email');
components[
  'loyalty-partner-subscriptions'
] = require('./loyalty-partner-subscriptions/desktop');
components['financial-user-info'] = require('./financial-user-info');
components['loyalty-benefits'] = require('./loyalty-buylevel6/desktop');
components['national-insurance-info'] = require('./national-insurance-info');
components.recommendations = require('../../commons/recommendations-snapped/desktop');
components['recommendations-grid'] = require('../../commons/recommendations-grid/desktop');
components['mplay-media-card'] = require('./advertising-adn-video-banner');
components['thb-desktop'] = require('./thb-desktop');

function HomeDesktop(props) {
  return <Home {...props} availableComponents={components} />;
}

module.exports = HomeDesktop;
