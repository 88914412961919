const React = require('react');
const { useEffect } = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const CarouselSnapped = require('@andes/carousel-snapped');
const classNames = require('classnames');
const withTracker = require('../../../commons/with-tracker');
const CarouselItem = require('./carousel-item');

const DESKTOP = 'desktop';
const MOBILE = 'mobile';

const carouselSettings = {
  global: {
    autoplaySpeed: 5000,
    arrowsVisible: false,
    slidesPerView: 1,
    spacing: 12,
  },
  mobile: {
    scroll: 'visible',
    speed: 275,
    pagination: false,
    type: 'content',
  },
  desktop: {
    scroll: 'hidden',
    speed: 500,
    pagination: true,
    type: 'full',
  },
};

function Carousel(props) {
  const {
    deviceType,
    i18n,
    elements,
    is_enabled_new_main_slider: isEnabledNewMainSlider,
    is_enabled_new_home_web_mobile: isEnableNewHomeWebMobile,
  } = props;
  const ariaLabel = i18n.gettext('novedades principales');
  const { global, mobile, desktop } = carouselSettings;

  useEffect(() => {
    const header = document.querySelector('.nav-header');
    if (header && isEnableNewHomeWebMobile) {
      header.classList.add('isEnableNewHomeWebMobile');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames('andes-carousel-snapped__exhibitor-wrapper', {
        'andes-carousel-snapped__hero': isEnabledNewMainSlider,
        'andes-carousel-snapped__new-home': isEnableNewHomeWebMobile,
      })}
    >
      <CarouselSnapped
        {...props}
        scroll={
          (deviceType === MOBILE && isEnabledNewMainSlider) || isEnableNewHomeWebMobile
            ? mobile.scroll
            : desktop.scroll
        }
        srLabel={ariaLabel}
        spacing={global.spacing}
        slidesPerView={global.slidesPerView}
        speed={deviceType === MOBILE ? mobile.speed : desktop.speed}
        type={
          (deviceType === MOBILE && isEnabledNewMainSlider) || isEnableNewHomeWebMobile
            ? mobile.type
            : desktop.type
        }
        autoplay={{ speed: global.autoplaySpeed }}
        arrows={deviceType === DESKTOP && { visibility: global.arrowsVisible ? 'always' : 'hover' }}
        pagination={
          (deviceType === MOBILE && isEnabledNewMainSlider) || isEnableNewHomeWebMobile
            ? mobile.pagination
            : desktop.pagination
        }
        infinite
      >
        {elements.map((product, index, array) => (
          <CarouselItem
            key={`sale-product-${product.title
              ? product.title.replace(/ /g, '-').toLowerCase() + index
              : product.order + index
            }`}
            {...product}
            index={index}
            {...isEnabledNewMainSlider}
            itemsLenght={array.length}
            deviceType={deviceType}
          />
        ))}
      </CarouselSnapped>
    </div>
  );
}

Carousel.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  deviceType: PropTypes.oneOf([DESKTOP, MOBILE]).isRequired,
  i18n: PropTypes.object.isRequired,
  is_enabled_new_main_slider: PropTypes.bool,
  is_enabled_new_home_web_mobile: PropTypes.bool,
  autoplay: PropTypes.bool,
  lazyLoad: PropTypes.bool,
};

module.exports = injectI18n(withTracker(Carousel));
