const INTERSTITIAL_PATH = '/loyalty/interstitial/modal';
const { trackEventWithExperiments, trackPage } = require('./melidata');

class componentInterstitialService {
  static interestedHandler(target) {
    if (typeof window === 'undefined' || !target) return;
    window.parent.location.href = target;
  }

  static notInterestedHandler() {
    if (typeof window === 'undefined') return;
    window.parent.postMessage({ type: 'interstitial:close' }, '*');
  }

  static trackInterestedHandlerClickEvent(trackInfo) {
    const { experiments, type } = trackInfo;
    const track = {
      ...trackInfo,
      type: 'event',
      path: INTERSTITIAL_PATH,
      event_data: {
        status: 'primary',
        type,
      },
      experiments,
    };

    trackEventWithExperiments(track);
  }

  static trackNotInterestedHandlerClickEvent(trackInfo) {
    const { experiments, type } = trackInfo;
    const track = {
      ...trackInfo,
      type: 'event',
      path: INTERSTITIAL_PATH,
      event_data: {
        status: 'secondary',
        type,
      },
      experiments,
    };
    trackEventWithExperiments(track);
  }

  static trackShowedInterstitial(trackInfo) {
    const { experiments, type } = trackInfo;
    const track = {
      ...trackInfo,
      type: 'event',
      path: INTERSTITIAL_PATH,
      event_data: {
        status: 'opened',
        type,
      },
      experiments,
    };
    trackPage(track);
  }
}

module.exports = componentInterstitialService;
