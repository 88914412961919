const React = require('react');
const { string, bool, shape } = require('prop-types');

const Image = require('../../../commons/image');

function CollectionItem({
  title,
  picture,
  discover_label,
  view_more_action: { target },
}) {
  return (
    <div>
      <a href={target} className="ui-card large">
        <Image className="lazyload" {...picture} alt={title} />
        <div className="ui-card foot-main">
          <span className="main-item-label">{discover_label}</span>
          <span className="main-item-title">{title}</span>
        </div>
      </a>
    </div>
  );
}

CollectionItem.propTypes = {
  discover_label: string,
  lazyLoad: bool.isRequired,
  picture: shape(Image.propTypes).isRequired,
  view_more_action: shape({
    target: string.isRequired,
  }).isRequired,
  title: string.isRequired,
};

CollectionItem.defaultProps = {
  discover_label: null,
  title: null,
};

module.exports = CollectionItem;
