const React = require('react');
const { arrayOf, shape, string, bool } = require('prop-types');

const CarouselSnapped = require('@andes/carousel-snapped');

const { CarouselSnappedSlide } = CarouselSnapped;
const injectI18n = require('nordic/i18n/injectI18n');
const OfficialStores = require('./official-stores');
const SlideDesktop = require('./slide-desktop');

function OfficialStoresDesktop({ elements, lazyLoad, lowEnd, i18n, ...rest }) {
  const slides = elements.map(element => ({
    ...element,
    id: element.target_url,
    lazyLoad,
    lowEnd,
  }));

  return (
    <OfficialStores {...{ elements, lazyLoad, lowEnd, i18n, ...rest }}>
      <CarouselSnapped slidesPerView={4} pagination={false}>
        {slides.map(item => (
          <CarouselSnappedSlide key={item.id}>
            <SlideDesktop {...item} />
          </CarouselSnappedSlide>
        ))}
      </CarouselSnapped>
    </OfficialStores>
  );
}

OfficialStoresDesktop.propTypes = {
  elements: arrayOf(
    shape({
      target_url: string.isRequired,
    }),
  ).isRequired,
  lazyLoad: bool.isRequired,
  lowEnd: bool.isRequired,
};

module.exports = injectI18n(OfficialStoresDesktop);
