const React = require('react');
const classnames = require('classnames');
const Props = require('./props');

const namespace = 'ui-homes-icon ui-homes-icon--chevron';

const IconChevron = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" fillRule="evenodd" strokeWidth="1.5" d="M1 1.343L6.657 7 1 12.657" />
  </svg>
);

IconChevron.propTypes = Props.propTypes;
IconChevron.defaultProps = Props.defaultProps;

module.exports = React.memo(IconChevron);
