/* istanbul ignore file */

const React = require('react');
const { array, string, shape, object, bool, oneOf } = require('prop-types');

const Dropdown = require('@andes/dropdown');

const { DropdownItem } = Dropdown;

const Action = require('../../../commons/action');
const Collection = require('./collection');
const Section = require('../../../commons/section');
const componentCtrService = require('../../../../services/component-ctr');
const withTracker = require('../../../commons/with-tracker');

class Collections extends React.Component {
  constructor(props) {
    super(props);
    this.updateCollection = this.updateCollection.bind(this);
    this.state = this.initNewCollection(props.default, true);
  }

  initNewCollection(collection, lazyLoad) {
    return {
      collection: this.formatItems(collection, lazyLoad),
    };
  }

  formatItems(data, lazyLoad) {
    const { metadata, restClient } = this.props;

    data.items = data.items.map((item) => ({
      permalink: item.target_url,
      price: {},
      shipping: { free_shipping: false, label: '' },
      ...item,
      metadata,
      restClient,
    }));
    if (data.picture) {
      data.picture.lazyLoad = lazyLoad;
    }

    return data;
  }

  /* istanbul ignore next */
  updateCollection(event) {
    const collectionId = event.target.value;
    const {
      collection: { id },
    } = this.state;

    const { restClient } = this.props;

    if (collectionId === id) {
      return;
    }

    restClient
      .get(`/collections/${collectionId}`)
      .then((response) => {
        this.setState(this.initNewCollection(response.data, false));
        componentCtrService.notifyPrint(response.data.items[0].track);
        componentCtrService.notifyPrint(response.data.track);
      })
      .catch(() => { });
  }

  render() {
    const { collection } = this.state;
    const {
      collections,
      title,
      deviceType,
      discover_label,
      lazyLoad,
      restClient,
    } = this.props;

    return (
      <Section type="collections">
        <div className="row container">
          <div className="section-header">
            <h2 className="collections__header">
              <span className={deviceType === 'desktop' ? 'cb-title' : ''}>
                {`${title}`}
              </span>
              <Dropdown
                value={collection.id}
                className="collections__header__dropdown"
                type="form"
                srLabel={collection.title}
                onChange={this.updateCollection}
              >
                {collections.map((collectionI) => (
                  <DropdownItem
                    value={collectionI.id}
                    title={collectionI.title}
                    key={collectionI.id}
                  />
                ))}
              </Dropdown>
            </h2>
          </div>
          <Collection
            {...collection}
            discover_label={discover_label}
            lazyLoad={lazyLoad}
            restClient={restClient}
            deviceType={deviceType}
            imgSize={deviceType === 'mobile' ? '160' : '184'}
          />
          {deviceType === 'mobile' && (
            <Action
              {...collection.view_more_action}
              className="section-button"
            />
          )}
        </div>
      </Section>
    );
  }
}

Collections.propTypes = {
  collections: array.isRequired,
  discover_label: string,
  default: shape({
    view_more_action: object.isRequired,
  }).isRequired,
  lazyLoad: bool.isRequired,
  metadata: shape({}).isRequired,
  restClient: object.isRequired,
  title: string,
  type: string.isRequired,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
};

Collections.defaultProps = {
  discover_label: null,
  title: null,
};

module.exports = withTracker(Collections);
