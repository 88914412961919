const React = require('react');
const { string, bool, shape } = require('prop-types');
const Image = require('../image');

const GridItem = ({
  url,
  lazyLoad,
  title,
  image,
  namespace: namespaceFromProps,
}) => {
  const namespace = `${namespaceFromProps}-item`;
  return (
    <a href={url} className={namespace}>
      <Image size={183} lazyLoad={lazyLoad} alt={title} {...image} />
    </a>
  );
};

GridItem.propTypes = {
  url: string.isRequired,
  lazyLoad: bool.isRequired,
  title: string,
  image: shape({}).isRequired,
  namespace: string,
};

module.exports = GridItem;
