const React = require('react');
const { node, string, shape } = require('prop-types');

const Section = require('../../../commons/section');
const Action = require('../../../commons/action');
const withTracker = require('../../../commons/with-tracker');

function OfficialStores({ title, type, view_more_action, children }) {
  return (
    <Section type={type}>
      <div className="row container">
        <div className="section-header">
          <h2>{title}</h2>
          <Action {...view_more_action} />
        </div>
        {children}
      </div>
    </Section>
  );
}

OfficialStores.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  type: string.isRequired,
  view_more_action: shape(Action.propTypes).isRequired,
};

module.exports = withTracker(OfficialStores);
