const React = require('react');
const { string, oneOf, shape } = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');
const isEmpty = require('lodash/isEmpty');

const INTERPOLATION_REGEXP = /{([\s\S]+?)}/g;

const getOutput = (as, className, content, forwardProps) => {
  const Element = as;
  return (
    <Element className={className} {...forwardProps}>
      {content}
    </Element>
  );
};

const ComposedLabel = ({
  as,
  className,
  text,
  values,
  i18n,
  ...forwardProps
}) => {
  let content = text;
  const accessibilityMessage = i18n.gettext('Se abrirá en una nueva ventana');
  if (!isEmpty(values)) {
    let count = 0;
    content = text.split(INTERPOLATION_REGEXP).map((word) => {
      if (Object.prototype.hasOwnProperty.call(values, word)) {
        const replacement = values[word];
        if (replacement.type === 'action') {
          return (
            <a
              key={replacement.target}
              target={replacement.html_target || '_self'}
              href={replacement.target}
            >
              {replacement.label.text}
              {replacement.html_target && (
                <span className="clipped"> {accessibilityMessage}</span>
              )}
            </a>
          );
        }

        if (replacement.type === 'line-break') {
          // eslint-disable-next-line no-plusplus
          return <br key={word + count++} />;
        }
      }
      return word;
    });
  }

  return getOutput(as, className, content, forwardProps);
};

ComposedLabel.propTypes = {
  as: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li']),
  className: string,
  text: string,
  values: shape({}),
  i18n: shape({}),
};

ComposedLabel.defaultProps = {
  as: 'p',
  className: null,
  text: '',
  values: null,
};

module.exports = injectI18n(ComposedLabel);
