// Traemos nuestro builder porque Homes no tiene uno como vpp/search
const IconFactory = require('@recommendations-fe/icons/factory-builder');
const {
  IconRecommendationsFull,
  IconRecommendationsMeliCoins,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
} = require('../icons/recommendations-fe/icon-generator');

const RecommendationsIconFactory = IconFactory([
  IconRecommendationsFull,
  IconRecommendationsMeliCoins,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
]);

module.exports = RecommendationsIconFactory;
