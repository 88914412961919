/* eslint-disable react/jsx-fragments */
const React = require('react');
const Script = require('nordic/script');
const { string, shape } = require('prop-types');
const Section = require('../../../commons/section');

function AdvertisingAdn({ content }) {
  return (
    <Section type="adn" className="AD_ADN_area">
      <React.Fragment>
        <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
        <div
          data-component="adn"
          data-content={JSON.stringify(content)}
        />
      </React.Fragment>
    </Section>
  );
}

AdvertisingAdn.propTypes = {
  content: shape({
    category: string.isRequired,
    page: string.isRequired,
    placement: string.isRequired,
    platform: string.isRequired,
    site_id: string.isRequired,
    slot_id: string.isRequired,
    title: string.isRequired,
  }).isRequired,
};

module.exports = AdvertisingAdn;
