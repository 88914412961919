const IconFull = require('../full');
const IconMeliCoins = require('../meli-coins');
const IconHeartEmpty = require('../heart-empty');
const IconHeartFull = require('../heart-full');
const IconChevron = require('../chevron');

const IconRecommendationsFull = {
  ...IconFull,
  ICON_ID: 'recommendations-full_icon',
};
const IconRecommendationsMeliCoins = {
  ...IconMeliCoins,
  ICON_ID: 'recommendations-meli_coins',
};
const IconRecommendationsHeartEmpty = {
  ...IconHeartEmpty,
  ICON_ID: 'recommendations-heart-empty',
};
const IconRecommendationsHeartFull = {
  ...IconHeartFull,
  ICON_ID: 'recommendations-heart-full',
};
const IconRecommendationsChevron = {
  ...IconChevron,
  ICON_ID: 'recommendations-chevron',
};
// @TODO: Falta agregar icon p/ CPG y Recomendado
// Cuando se agreguen los casos en el middleend
// No se traen desde Recommendations-fe para no subir peso

module.exports = {
  IconRecommendationsFull,
  IconRecommendationsMeliCoins,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
};
