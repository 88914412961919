const React = require('react');
const Script = require('nordic/script');

const CloseModalEmbed = () => (
  <Script>
    {`
      window.addEventListener("message", (event) => {
        if (event.data.type !== 'interstitial:close') return;
        var modal = document.querySelector(".andes-modal__portal");
        var html = document.getElementsByTagName('html')[0]
        modal.style.visibility = "hidden"
        html.style.overflow=''
        html.style.paddingRight=''
      }, false);
      `}
  </Script>
);

module.exports = CloseModalEmbed;
