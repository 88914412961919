const React = require('react');
const classNames = require('classnames');
const Image = require('../../../commons/image');

const BenefitItem = ({ icon, description, center }) => (
  <div
    className={classNames('loyalty-buylevel6__body-benefit', {
      'loyalty-buylevel6__body-benefit--center': center,
    })}
  >
    <div className="loyalty-buylevel6__body-benefit-logo">
      <Image
        className="loyalty-buylevel6__body-benefit-logo-img"
        src={icon.url.src}
        alt=""
        lazyLoad
      />
    </div>
    <span className="loyalty-buylevel6__body-benefit-text">{description}</span>
  </div>
);

module.exports = BenefitItem;
