const React = require('react');
const Script = require('nordic/script');

const ImageLoader = () => (
  <Script on="now">
    {`
      window.addEventListener('load', function() {
        var exhibitor = document.querySelector('.andes-carousel-snapped__slide img');
        var originalSrc = exhibitor.getAttribute('data-original-src');
        if (exhibitor && originalSrc) {
          var img = new Image();
          img.onload = function() { exhibitor.src = img.src; };
          img.src = exhibitor.getAttribute('data-original-src');
          exhibitor.removeAttribute('data-original-src');
        }
      });
    `}
  </Script>
);

module.exports = ImageLoader;
