const React = require('react');
const { shape, arrayOf, bool, string, object } = require('prop-types');

const Action = require('../../../commons/action');
const Image = require('../../../commons/image');
const OfficialStoresSlide = require('./official-stores-slide');

function OfficialStoresSlideDesktop({ title, items, view_more_action, lazyLoad, ...rest }) {
  const imgSize = '70';
  const isDesktop = true;
  return (
    <OfficialStoresSlide {...{ title, items, view_more_action, lazyLoad, isDesktop, ...rest }}>
      {/*  Wrapper to revert the order via flex-order and tab first (for html order) on the slide, then items. */}
      <div className="official-stores-slide-order-wrapper">
        <div className="official-stores-slide-action">
          <Action {...view_more_action} id={null} accesibilityText={`, ${title}`} />
        </div>
        <div className="official-stores-slide-items">
          {items.map(item => (
            <a key={item.id} className="official-stores-slide-item" href={item.permalink}>
              <Image size={imgSize} {...item.picture} lazyLoad={lazyLoad} alt={item.title} />
            </a>
          ))}
        </div>
      </div>
    </OfficialStoresSlide>
  );
}

OfficialStoresSlideDesktop.propTypes = {
  background_picture: shape(Image.propTypes).isRequired,
  items: arrayOf(object),
  lazyLoad: bool.isRequired,
  logo_picture: shape(Image.propTypes).isRequired,
  title: string.isRequired,
  view_more_action: shape(Action.propTypes).isRequired,
};

OfficialStoresSlideDesktop.defaultProps = {
  items: [],
};

module.exports = OfficialStoresSlideDesktop;
