const React = require('react');
const { arrayOf, shape, string, bool } = require('prop-types');

const InfoSlide = require('./info-slide');
const Section = require('../../../commons/section');
const withTracker = require('../../../commons/with-tracker');

function SiteShoppingInfoDesktop({ type, elements, lazyLoad }) {
  return (
    <Section type={type}>
      <div className="container">
        {elements.map(element => (
          <InfoSlide key={element.title_label} {...element} lazyLoad={lazyLoad} />
        ))}
      </div>
    </Section>
  );
}

SiteShoppingInfoDesktop.propTypes = {
  elements: arrayOf(
    shape({
      title_label: string.isRequired,
    }),
  ).isRequired,
  type: string.isRequired,
  lazyLoad: bool.isRequired,
};

module.exports = withTracker(SiteShoppingInfoDesktop);
