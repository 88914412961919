const React = require('react');
const classNames = require('classnames');
const injectI18n = require('nordic/i18n/injectI18n');
const CarouselSnapped = require('@andes/carousel-snapped');
const Section = require('../../../commons/section');
const Action = require('../../../commons/action');
const SlideDesktop = require('./slide.desktop');

const { CarouselSnappedSlide } = CarouselSnapped;

function LoyaltyPartnerSubscriptions({ loyalty_partner_card_info }) {
  const {
    title,
    type,
    elements,
    home_button: view_more_action,
    lowEnd,
    lazyLoad,
  } = loyalty_partner_card_info;

  const items = elements.map((element) => ({
    ...element,
    id: element.target,
    lazyLoad,
    lowEnd,
    only_element: elements.length === 1,
  }));

  return (
    <Section type={type} className="partners-subscriptions">
      <div className="row container">
        <div className="section-header">
          <h2>{title}</h2>
          {view_more_action && <Action {...view_more_action} />}
        </div>
        <div
          className={classNames('partners-subscriptions__body', {
            'partners-subscriptions__body-show-always-arrows':
              elements.length > 3,
          })}
        >
          <CarouselSnapped
            pagination={false}
            lazyload={false}
            slidesPerView={elements.length >= 3 ? 3 : elements.length}
          >
            {items.map((item) => (
              <CarouselSnappedSlide key={item.id}>
                <SlideDesktop {...item} />
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        </div>
      </div>
    </Section>
  );
}

module.exports = injectI18n(LoyaltyPartnerSubscriptions);
