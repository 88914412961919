function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

module.exports = function displayName(HigherOrder, Wrapped) {
  const higherOrderName = getDisplayName(HigherOrder);
  const wrappedName = getDisplayName(Wrapped);

  return `${higherOrderName}(${wrappedName})`;
};
