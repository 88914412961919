const React = require('react');
const { string, shape } = require('prop-types');
const Section = require('../section');

const Image = require('../image');

const namespace = 'complaints-book';
const imageSize = '30';
const DESKTOP = 'desktop';

const ComplaintsBook = ({ type, picture, url, deviceType }) => {
  const Element = deviceType === DESKTOP ? 'div' : Section;

  return (
    <Element className={type}>
      <a className={`${namespace}__link`} href={url.target} target="_blank" rel="noreferrer">
        <Image className={`${namespace}__image`} size={imageSize} {...picture} />
      </a>
    </Element>
  );
};

ComplaintsBook.propTypes = {
  type: string.isRequired,
  picture: shape({
    src: string.isRequired,
    src2x: string.isRequired,
  }).isRequired,
  url: shape({
    target: string.isRequired,
  }).isRequired,
  deviceType: string.isRequired,
};

module.exports = ComplaintsBook;
