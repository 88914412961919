module.exports = {
  LAZYLOAD_ON: 'on',
  LAZYLOAD_OFF: 'off',
  STATIC_ITEM: 'STATIC_ITEM',
  STATIC_ICON: 'STATIC_ICON',
  STATIC_ILLUSTRATION: 'STATIC_ILLUSTRATION',
  TYPE_PRICE: 'price',
  TYPE_ICON: 'icon',
  INTERPOLATION_REGEXP: /{([\s\S]+?)}/g,
  DISCOUNT_FONT_SIZE: 10,
  DYNAMIC_ACCESS_SIZE: {
    SMALL: 'small',
    MEDIUM: 'medium',
    LONG: 'long',
  },
  DYNAMIC_ACCESS_MODAL: [
    'payment_methods',
  ],
  DYNAMIC_ACCESS_CUSTOM_CARD: [
    'location',
  ],
};
