const React = require('react');
const { func, string, bool, object, shape } = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');
const classNames = require('classnames');
const SvgCross = require('./cross.svg');
const SquaredItem = require('../../../commons/squared-item');
const withTracker = require('../../../commons/with-tracker');

const FADEOUT_INTERVAL = 300;

class NavigationSlide extends React.Component {
  constructor(props) {
    super(props);
    this.startDeleteAnimation = this.startDeleteAnimation.bind(this);
    this.delete = this.delete.bind(this);
    this.state = { deleting: false };
  }

  startDeleteAnimation() {
    this.setState({ deleting: true }, () => setTimeout(this.delete, FADEOUT_INTERVAL));
  }

  validateKeyDown(e) {
    const code = e.charCode || e.keyCode;
    if ((code === 32) || (code === 13)) {
      this.startDeleteAnimation();
    }
  }

  delete() {
    const {
      id,
      restClient,
      delete: deleteFn,
      showError,
    } = this.props;

    restClient.delete(`/navigation/${id}`)
      .then(() => deleteFn(id))
      .catch(() => {
        this.setState({ deleting: false }, showError());
      });
  }

  render() {
    const { deleting } = this.state;

    const {
      lowEnd,
      i18n,
    } = this.props;

    return (
      <div className={classNames('navigation-slide', deleting && 'hide')}>
        <SquaredItem {...this.props} />
        {!lowEnd
          && (
          <button
            data-testid="cross-delete"
            className="cross-container"
            disabled={deleting}
            onClick={this.startDeleteAnimation}
            onKeyDown={e => this.validateKeyDown(e)}
          >
            <span className="clipped">
              {i18n.gettext('Eliminar')}
            </span>
            <div className="cross-circle">
              <div className="cross">
                <SvgCross />
              </div>
            </div>
          </button>
          )}
      </div>
    );
  }
}

NavigationSlide.propTypes = {
  delete: func.isRequired,
  id: string.isRequired,
  lowEnd: bool.isRequired,
  restClient: object.isRequired,
  showError: func.isRequired,
  i18n: shape({
    gettext: func,
  }).isRequired,
};

module.exports = injectI18n(withTracker(NavigationSlide));
