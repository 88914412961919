const React = require('react');
const { string, arrayOf, shape } = require('prop-types');

const classnames = require('classnames');
const Section = require('../section');
const ComposedLabel = require('../composed-label');
const withTracker = require('../with-tracker');

const namespace = 'regret';

const Regret = ({ type, elements }) => (
  <Section type={type}>
    <div className={classnames('container', `${namespace}__container`)}>
      {elements.map((element) => (
        <div className={`${namespace}__box`} key={element.text}>
          <ComposedLabel className={`${namespace}__content`} {...element} />
        </div>
      ))}
    </div>
  </Section>
);

Regret.propTypes = {
  type: string.isRequired,
  elements: arrayOf(
    shape({
      text: string.isRequired,
      color: string,
      bg_color: string,
    }),
  ).isRequired,
};

module.exports = withTracker(Regret);
